import PropTypes from 'prop-types';
import {
  Avatar,
  Box, Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import { useState } from 'react';
import Popup from '../Popup';
import DisplayForm from './DisplayForm';

const DisplayCard = ({ display, ...rest }) => {
  const [openPopup, setOpenPopup] = useState(false);
  console.log(display);
  const openInPopup = () => {
    setOpenPopup(true);
  };
  let individual = 'red';
  let equipment = 'red';
  let fun = 'red';
  let special = 'red';
  if (parseInt(display.individual) !== 0) {
    individual = 'green';
  }
  if (parseInt(display.equipment) !== 0) {
    equipment = 'green';
  }
  if (parseInt(display.fun) !== 0) {
    fun = 'green';
  }
  if (parseInt(display.special) !== 0) {
    special = 'green';
  }
  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
        {...rest}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pb: 3
            }}
          >
            <Avatar
              alt='Product'
              src='/static/images/products/product_0.png'
              variant='square'
            />
          </Box>
          <Typography
            align='center'
            color='textPrimary'
            gutterBottom
            variant='h4'
          >
            {display.name}
          </Typography>
          <Typography
            align='center'
            color='textPrimary'
            variant='body1'
          >
            {display.description}
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Box sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <FiberManualRecordSharpIcon style={{ fill: 'grey' }} />
              <Typography
                align='center'
                color='textPrimary'
                variant='body1'
              >
                N/A
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  openInPopup(display);
                }}
              >
                Bearbeiten
              </Button>
            </Grid>

          </Grid>
        </Box>
      </Card>
      <Popup
        title='Display bearbeiten'
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <DisplayForm data={display} setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
};

DisplayCard.propTypes = {
  display: PropTypes.object.isRequired
};

export default DisplayCard;

//              <FiberManualRecordSharpIcon style={{ fill: individual }} />
//               <FiberManualRecordSharpIcon style={{ fill: special }} />
//               <FiberManualRecordSharpIcon style={{ fill: equipment }} />
//               <FiberManualRecordSharpIcon style={{ fill: fun }} />
