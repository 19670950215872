import { Helmet } from 'react-helmet';
import { Box, Card, Container, Typography } from '@material-ui/core';

const Impressum = () => (
    <>
      <Helmet>
        <title>Impressum | KK Hosting Display System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '30px',
              paddingTop: '5px'
            }}
          >
            <Box sx={{ pt: 3, mb: 2 }}>
              <Typography
                color='textPrimary'
                variant='h1'
              >
                Impressum
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h4'
              >
                KK Hosting- und IT-Service
                Internetagentur
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                <p>Rainer Kappus</p>
                <p>Straße: Rohrgraben 6</p>
                <p>Ort: 79114 Freiburg</p>
                <p> Tel. +49-761-458912-0</p>
                <p>Fax +49-761-458912-19</p>
                <p>E-mail: <a href='mailto:mail@kk-hosting.de'>mail@kk-hosting.de</a></p>
                <p>Internet: <a href='https://kk-hosting.de/'>www.kk-hosting.de</a></p>
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                <p>Inhaber: Rainer Kappus</p>
                <p>USt-IdNr.: DE 198-370-401</p>
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                <p>Verantwortlich für den Inhalt im Sinne § 7 TMG : Rainer Kappus</p>
                <p>Provider: KK Hosting- und IT-Service, Rainer Kappus</p>
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h5'
              >
                Haftungsausschluss
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h6'
              >
                1. Inhalte
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                Alle Inhalte wurden sorgfältig von Seiten des Autors auf der Grundlage aktueller Kenntnisse und Grundlagen
                erstellt. Eine Verantwortung ergibt sich für den Autor nur im Rahmen allgemein gültiger Gesetze. Dies gilt
                insbesondere für selbst erbrachte Inhalte nach § 7 TMG und für fremde Inhalte gem. §§ 8 – 10 TMG. Der
                Anbieter dieser Webseite ist für fremde Inhalte erst nach Kenntniserlangung einer konkreten
                Rechtsverletzung
                verantwortlich.

              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h6'
              >
                2. Verlinkungen
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                Inhalte, auf die der Autor durch sogenannte „Hyperlinks“ direkt oder indirekt verweist, liegen außerhalb
                des
                Einfluss- und Verantwortungsbereiches und werden somit nicht zu eigen gemacht. Alle Webseiten wurden zum
                Zeitpunkt der Verlinkung sorgfältig auf illegale Inhalte überprüft. Da der Autor auf die Inhalte
                verlinkter
                Webseiten keinen Einfluss hat, distanziert er sich von allen Inhalten, die nach der Verlinkung und Prüfung
                geändert wurden. Für Inhalte und auch Schäden, die durch die Nutzung von verlinkten Informationen
                entstehen
                können, haftet alleine der Autor der Webseite, auf welche verlinkt wurde. Erhält der Autor Kenntnis von
                Inhalten auf verlinkten Webseiten, die illegal, rechtswidrig oder fehlerhaft sind, so werden diese
                Verweise
                gelöscht.

              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h6'
              >
                3. Urheberrechte
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                Diese Website beinhaltet Daten und Informationen aller Art, die marken- und/oder nach deutschem
                Urheberecht
                geschützt sind. Es ist daher nicht gestattet, die Website im Ganzen oder einzelne Teile davon
                herunterzuladen, zu vervielfältigen und zu verbreiten.

              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h6'
              >
                4. Datenschutz
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                Die in Formularen auf dieser Webseite abgelegten Daten und Angaben werden auf den Systemen des Autors
                zwischengespeichert. Dies geschieht rein zum Zweck der Datenverarbeitung im Sinne des entsprechenden
                Services.

              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textPrimary'
                variant='h6'
              >
                5. Gleichstellung
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Typography
                color='textSecondary'
                variant='body2'
              >
                Um die Lesbarkeit und das Verständnis der Texte zu gewährleisten, wird meist nur eine Geschlechtsform
                verwendet. Unbeeinflusst davon beziehen sich alle Angaben dieser Webseite auf alle Geschlechter.

              </Typography>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  )
;

export default Impressum;
