import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box, Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { MyContext } from '../../MyContext';
import Popup from '../Popup';
import { BASE_URL } from '../../utils/constants';

const CONTENT_TYPES = [{ 'equipment': 'Zubehör', 'special': 'Aktionen', 'fun': 'Spezialbereich' }];

let getContentType = (key) => {
  return (CONTENT_TYPES.find(ele => key in ele) || {})[key];
};

const MediaListResults = ({ media, ...rest }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const { updateState, deleteMedia } = useContext(MyContext);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentMediaType, setCurrentMediaType] = useState('');

  const openInPopup = (media) => {
    let ext = getFileExtension(media);
    setCurrentMediaType(ext);
    setCurrentMedia(media);
    setOpenPopup(true);
  };

  const getFileExtension = (media) => {
    let re = /(?:\.([^.]+))?$/;
    let ext = re.exec(media.location)[1];
    return ext;
  };

  const displayMediaType = () => {
    if (currentMediaType === 'mp4') {
      return <video width={'100%'} src={BASE_URL + currentMedia.location} controls autoPlay muted />;
    } else {
      return <img width={'100%'} src={BASE_URL + currentMedia.location} alt={currentMedia.description} />;
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const applyPagination = (media, page, limit) => {
    return media.slice(page * limit, page * limit + limit);
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 200 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Kategorie
                  </TableCell>
                  <TableCell>
                    Beschreibung
                  </TableCell>
                  <TableCell>
                    Größe
                  </TableCell>
                  <TableCell>
                    Löschen
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applyPagination(media, page, limit).map((media_type) => (
                  <TableRow
                    hover
                    key={media_type.id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={BASE_URL + media_type.location}
                          sx={{ mr: 2 }}
                          onClick={(e) =>
                            openInPopup(media_type)}
                        >
                          {getFileExtension(media_type)}
                        </Avatar>
                        <Typography
                          color='textPrimary'
                          variant='body1'
                          onClick={(e) =>
                            openInPopup(media_type)}
                        >
                          {getContentType(media_type.categorie)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='textPrimary'
                        variant='body1'
                        onClick={(e) =>
                          openInPopup(media_type)}
                      >
                        {media_type.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='textPrimary'
                        variant='body1'
                        onClick={(e) =>
                          openInPopup(media_type)}
                      >
                        {`${(media_type.size / 1000 / 1000).toFixed(2)} mb`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                          await deleteMedia(media_type).then(
                            updateState(media_type)
                          );
                        }}
                      >
                        X
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component='div'
          count={media.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' von ' + count;
            }
          }
          labelRowsPerPage={' Medien pro Seite:'}
          showFirstButton
          showLastButton
          getItemAriaLabel={(type, page, selected) => {
            if (type === 'page') {
              return selected ? '' : ('Zu Seite' + page);
            }
            if (type === 'first') {
              return 'Zur ersten Seite';
            }
            if (type === 'last') {
              return 'Zur letzten Seite';
            }
            if (type === 'next') {
              return 'Zur nächsten Seite';
            }
            if (type === 'previous') {
              return 'Zur vorherigen Seite';
            }
            return undefined;
          }}
        />
      </Card>
      <Popup
        title='Vorschau'
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        {displayMediaType()}
      </Popup>
    </>

  );
};

MediaListResults.propTypes = {
  media: PropTypes.array.isRequired
};

export default MediaListResults;
