import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import DisplayList from './pages/DisplayList';
import Login from './pages/Login';
import MediaList from './pages/MediaList';
import Impressum from './pages/Impressum';

const routes = (isLoggedIn) => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to='/login' />,
    children: [
      { path: 'displays', element: <DisplayList /> },
      { path: 'media', element: <MediaList /> },
      { path: 'impressum', element: <Impressum /> },
      { path: '*', element: <Navigate to='/app/displays' /> }
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to='/app/displays' />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to='/login' /> },
      { path: '*', element: <Navigate to='/login' /> }
    ]
  }
];
export default routes;
