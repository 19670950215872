import React, { Component, createContext } from 'react';
import axios from 'axios';
import {
  API_DELETE_MEDIA,
  API_GET_DATA,
  API_UPDATE_DISPLAY,
  API_UPLOAD_MEDIA, BASE_PLUS_API_URL
} from './utils/constants';

export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
  baseURL: BASE_PLUS_API_URL
});

class MyContextProvider extends Component {
  constructor() {
    super();
    this.isLoggedIn();
  }

  // Root State
  state = {
    showLogin: true,
    isAuth: false,
    theUser: null,
    data: null
  };

  // Toggle between Login & Signup page
  toggleNav = () => {
    const showLogin = !this.state.showLogin;
    this.setState({
      ...this.state,
      showLogin
    });
  };

  // On Click the Log out button
  logoutUser = () => {
    localStorage.removeItem('loginToken');
    this.setState({
      ...this.state,
      isAuth: false
    });
  };

  registerUser = async (user) => {
    // Sending the user registration request
    const register = await Axios.post('register.php', {
      name: user.name,
      email: user.email,
      password: user.password
    });

    return register.data;
  };


  loginUser = async (user) => {
    // Sending the user Login request
    const login = await Axios.post('login.php', {
      email: user.email,
      password: user.password
    });
    return login.data;
  };

  uploadMedia = async (image) => {
    const response = await axios({
      method: 'post',
      url: API_UPLOAD_MEDIA,
      data: image,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  };
  deleteMedia = async (image) => {
    const response = await axios.post(API_DELETE_MEDIA, {
      id: image.id,
      location: image.location
    });
    return response.data;
  };

  getData = async (user) => {
    const data = await axios.post(API_GET_DATA, {
      branchID: user.branchID
    });
    return data.data;
  };

  updateDisplay = async (display) => {
    return await axios.put(API_UPDATE_DISPLAY, {
      name: display.name,
      description: display.description,
      individual: display.individual,
      special: display.special,
      equipment: display.equipment,
      fun: display.fun,
      branchID: display.branchID,
      customer: display.customer,
      display: display.display,
      searchID: display.searchID
    });
  };

  updateState = async (user) => {
    this.setState({
      ...this.state,
      data: await this.getData(user)
    });
  };

  // Checking user logged in or not
  isLoggedIn = async () => {
    const loginToken = localStorage.getItem('loginToken');

    // If inside the local-storage has the JWT token
    if (loginToken) {

      //Adding JWT token to axios default header
      Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;

      // Fetching the user information
      const { data } = await Axios.get('user-info.php');
      // If user information is successfully received
      if (data.success && data.user) {
        this.setState({
          ...this.state,
          isAuth: true,
          theUser: data.user,
          data: await this.getData(data.user)
        });
      }

    }
  };

  render() {
    const contextValue = {
      rootState: this.state,
      toggleNav: this.toggleNav,
      isLoggedIn: this.isLoggedIn,
      registerUser: this.registerUser,
      loginUser: this.loginUser,
      logoutUser: this.logoutUser,
      getData: this.getData,
      updateDisplay: this.updateDisplay,
      updateState: this.updateState,
      deleteMedia: this.deleteMedia,
      uploadMedia: this.uploadMedia
    };
    return (
      <MyContext.Provider value={contextValue}>
        {this.props.children}
      </MyContext.Provider>
    );
  }

}

export default MyContextProvider;
