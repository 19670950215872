import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import MediaListResults from '../components/media/MediaListResults';
import MediaListToolbar from '../components/media/MediaListToolbar';
import { useContext } from 'react';
import { MyContext } from '../MyContext';

const MediaList = () => {
  const { rootState } = useContext(MyContext);
  const media = rootState.data.media;
  return (
    <>
      <Helmet>
        <title>Inhalte | KK Hosting Display System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <MediaListToolbar media={media} />
          <Box sx={{ pt: 3 }}>
            <MediaListResults media={media} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MediaList;
