import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import {
  Box,
  Button,
  Container, InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { useContext } from 'react';
import { MyContext } from '../../MyContext';


const DisplayForm = (product) => {
  const { updateState, updateDisplay } = useContext(MyContext);
  return (
    <>
      <Helmet>
        <title>Display bearbeiten | KK Hosting Display System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth='md'>
          <Formik
            initialValues={{
              name: product.data.name,
              description: product.data.description,
              individual: product.data.individual,
              special: product.data.special,
              equipment: product.data.equipment,
              fun: product.data.fun,
              branchID: product.data.branchID,
              customer: product.data.customer,
              display: product.data.display,
              searchID: product.data.searchID
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255, 'Der Name muss gleich oder kleiner 255 Zeichen sein')
                .required('Bitte geben Sie dem Display einen Namen'),
              description: Yup.string().max(255, 'Die Beschreibung muss gleich oder kleiner 255 Zeichen sein')
                .required('Bitte geben Sie dem Display eine Beschreibung'),
              individual: Yup.number().integer('Anzeigedauer muss eine ganze Zahl sein')
                .min(0, 'Anzeigedauer muss gleich oder größer 0 min sein')
                .max(255, 'Anzeigedauer muss gleich oder kleiner 255 min sein')
                .required('Bitte vergeben Sie ein Anzeigedauer. 0 = Nicht anzeigen')
                .test('oneValid', 'Eine Anzeigedauer muss sich von 0 unterscheiden',
                  function(value) {
                    return (value !== 0 || this.parent.special !== 0 || this.parent.equipment !== 0 || this.parent.fun !== 0);
                  }),
              special: Yup.number().integer('Anzeigedauer muss eine ganze Zahl sein')
                .min(0, 'Anzeigedauer muss gleich oder größer 0 min sein')
                .max(255, 'Anzeigedauer muss gleich oder kleiner 255 min sein')
                .required('Bitte vergeben Sie ein Anzeigedauer. 0 = Nicht anzeigen'),
              equipment: Yup.number().integer('Anzeigedauer muss eine ganze Zahl sein')
                .min(0, 'Anzeigedauer muss gleich oder größer 0 min sein')
                .max(255, 'Anzeigedauer muss gleich oder kleiner 255 min sein')
                .required('Bitte vergeben Sie ein Anzeigedauer. 0 = Nicht anzeigen'),
              fun: Yup.number().integer('Anzeigedauer muss eine ganze Zahl sein')
                .min(0, 'Anzeigedauer muss gleich oder größer 0 min sein')
                .max(255, 'Anzeigedauer muss gleich oder kleiner 255 min sein')
                .required('Bitte vergeben Sie ein Anzeigedauer. 0 = Nicht anzeigen')
            })}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              const data = await updateDisplay(values);
              if (data.data.success) {
                await updateState(product.data);
                product.setOpenPopup(false);
              } else {
                setFieldError('name', 'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie den Administrator.');
                setSubmitting(false);
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color='textPrimary'
                    variant='h2'
                  >
                    {product.data.name} bearbeiten
                  </Typography>
                  <Typography
                    color='textSecondary'
                    gutterBottom
                    variant='body2'
                  >
                    Bearbeiten Sie die Konfiguration Ihres Displays
                  </Typography>
                </Box>
                <Box sx={{ mb: 0, '& > :not(style)': { m: 1 } }}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    style={{ width: '97%' }}
                    label='Display Name'
                    margin='normal'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='name'
                    value={values.name}
                    variant='outlined'
                  />
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    style={{ width: '97%' }}
                    multiline
                    rows={3}
                    label='Beschreibung'
                    margin='normal'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='description'
                    value={values.description}
                    variant='outlined'
                  />
                </Box>
                <Box sx={{ mb: 0, '& > :not(style)': { m: 1 } }}>
                  <TextField
                    error={Boolean(touched.individual && errors.individual)}
                    label='Carzilla Individuelle Darstellung'
                    margin='normal'
                    name='individual'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='number'
                    value={values.individual}
                    variant='outlined'
                    InputProps={{
                      endAdornment: <InputAdornment position='start'>min</InputAdornment>
                    }}
                  />
                  <TextField
                    error={Boolean(touched.special && errors.special)}
                    label='Aktionen Darstellung'
                    margin='normal'
                    name='special'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='number'
                    value={values.special}
                    variant='outlined'
                    InputProps={{
                      endAdornment: <InputAdornment position='start'>min</InputAdornment>
                    }}
                  />
                </Box>
                <Box sx={{ mb: 0, '& > :not(style)': { m: 1 } }}>
                  <TextField
                    error={Boolean(touched.equipment && errors.equipment)}
                    label='Zubehör Darstellung'
                    margin='normal'
                    name='equipment'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='number'
                    value={values.equipment}
                    variant='outlined'
                    InputProps={{
                      endAdornment: <InputAdornment position='start'>min</InputAdornment>
                    }}
                  />
                  <TextField
                    error={Boolean(touched.fun && errors.fun)}
                    label='Spezialbereich Darstellung'
                    margin='normal'
                    name='fun'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='number'
                    value={values.fun}
                    variant='outlined'
                    InputProps={{
                      endAdornment: <InputAdornment position='start'>min</InputAdornment>
                    }}
                  />
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='fun' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='equipment' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='special' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='individual' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='name' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='description' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='oneValid' /> </Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Speichern
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default DisplayForm;
