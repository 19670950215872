import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Pagination
} from '@material-ui/core';
import DisplayCard from '../components/display/DisplayCard';
import { useContext } from 'react';
import { MyContext } from '../MyContext';

const DisplayList = () => {
  const { rootState } = useContext(MyContext);
  const displayData = rootState.data.display;

  return (
    <>
      <Helmet>
        <title>Displays | KK Hosting Display System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              {displayData.map((display) => (
                < Grid
                  item
                  key={display.id}
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <DisplayCard display={display} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color='primary'
              count={1}
              size='small'
            />
          </Box>
        </Container>
      </Box>
    </>
  );

};

export default DisplayList;
