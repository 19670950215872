import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  Monitor as MonitorIcon,
  Image as ImageIcon
} from 'react-feather';
import NavItem from './NavItem';
import { MyContext } from '../MyContext';

const items = [
  {
    href: '/app/displays',
    icon: MonitorIcon,
    title: 'Displays'
  },
  {
    href: '/app/media',
    icon: ImageIcon,
    title: 'Inhalte'
  }
];
/*,
*/

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { rootState } = useContext(MyContext);

  const user = {
    avatar: 'https://avatars.dicebear.com/api/initials/:' +
      rootState.theUser.firstname + ' ' +
      rootState.theUser.lastname + 'seed.svg',
    company: rootState.theUser.company,
    firstname: rootState.theUser.firstname,
    lastname: rootState.theUser.lastname
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to='/app/account'
        />
        <Typography
          color='textPrimary'
          variant='h5'
        >
          {user.firstname} {user.lastname}
        </Typography>
        <Typography
          color='textSecondary'
          variant='body2'
        >
          {user.company}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ p: 2 }}>
        <NavItem
          href='/app/impressum'
          key='Impressum'
          title='Impressum'
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor='left'
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
