import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MyContextProvider from './MyContext';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';


Sentry.init({
  dsn: 'https://ec7ce595f7744d17a829372f9b6437cb@o1058344.ingest.sentry.io/6045966',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2
});


ReactDOM.render(
  (
    <MyContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MyContextProvider>
  ),
  document.getElementById('root')
);
