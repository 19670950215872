import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  MenuItem, InputLabel, FormControl, Select, NativeSelect
} from '@material-ui/core';
import { useContext } from 'react';
import { MyContext } from '../../MyContext';
import { DEV_UPLOAD } from '../../utils/constants';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4'];

export const validateImageType = (value) => {
  if (value) {
    let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_FORMATS.includes(type);
  }
};

const MediaForm = (props) => {
  const { rootState, updateState, uploadMedia } = useContext(MyContext);
  const user = rootState.theUser;
  return (
    <>
      <Helmet>
        <title>Inhalt hochladen | KK Hosting Display System</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth='md'>
          <Formik
            initialValues={{
              categorie: '',
              description: '',
              branchID: user.branchID,
              sendimage: null
            }}
            validationSchema={Yup.object().shape({
              description: Yup.string().max(255, 'Die Beschreibung muss gleich oder kleiner 255 Zeichen sein')
                .required('Bitte geben Sie der Datei eine Beschreibung'),
              sendimage: Yup.mixed()
                .test('fileSize', 'Die Datei muss kleiner als 50mb sein', (value) => value === null || (value && value.size <= 50000000))
                .test('fileType', 'Die Datei ist kein JPG, PNG oder MP4', (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type)))
            })}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              const formData = new FormData();
              formData.append('sendimage', values.sendimage);
              formData.append('categorie', values.categorie);
              formData.append('description', values.description);
              formData.append('branchID', parseInt(values.branchID));
              const data = await uploadMedia(formData);
              if (data.data.success) {
                await updateState(user);
                props.setOpenPopup(false);
              } else {
                if (data.data.message === 'Sorry, file already exists check upload folder') {
                  setFieldError('categorie', 'Eine Datei mit diesem Namen existiert bereits');
                } else {
                  setFieldError('categorie', 'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie den Administrator');
                }
                setSubmitting(false);
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 0, '& > :not(style)': { m: 1 } }}>
                  <FormControl sx={{ mb: 0, minWidth: '97%' }}>
                    <InputLabel id='select-helper-label'>Kategorie</InputLabel>
                    <Select
                      labelId='select-helper-label'
                      id='select-helper-label'
                      value={values.categorie}
                      label='Kategorie'
                      onChange={(event) => {
                        setFieldValue('categorie', event.target.value);
                      }}
                    >
                      <MenuItem value='special'>Aktionen</MenuItem>
                      <MenuItem value='equipment'>Zubehör</MenuItem>
                      <MenuItem value='fun'>Spezialbereich</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    style={{ width: '97%' }}
                    multiline
                    rows={3}
                    label='Beschreibung'
                    margin='normal'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='description'
                    value={values.description}
                    variant='outlined'
                  />
                  <Typography
                    color='textPrimary'
                    variant='caption'
                  >
                    Größe kleiner 50 mb, JPG / PNG / MP4
                  </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='categorie' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='description' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='sendimage' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='fileSize' /> </Typography>
                  <Typography color='textSecondary' gutterBottom variant='body2' sx={{ color: '#d2302f' }}>
                    <ErrorMessage name='fileType' /> </Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Button
                    variant='contained'
                    component='label'
                    size='large'
                    fullWidth
                  >
                    Datei auswählen
                    <input
                      id='sendimage'
                      name='sendimage'
                      type='file'
                      hidden
                      onChange={(event) => {
                        setFieldValue('sendimage', event.currentTarget.files[0]);
                      }} />
                  </Button>
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Hochladen
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default MediaForm;
