import {
  Box,
  Button
} from '@material-ui/core';
import { useState } from 'react';
import Popup from '../Popup';
import MediaForm from './MediaForm';

const MediaListToolbar = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const openInPopup = () => {
    setOpenPopup(true);
  };
  return (
    <>
      <Box {...props}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              openInPopup();
            }}
          >
            Inhalt hinzufügen
          </Button>
        </Box>
      </Box>
      <Popup
        title='Inhalt hochladen'
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <MediaForm setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
};

export default MediaListToolbar;
