import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { useContext } from 'react';
import { MyContext } from './MyContext';
import Pace from './utils/Pace';

const App = () => {
  const { rootState } = useContext(MyContext);
  const { isAuth } = rootState;
  const content = useRoutes(routes(isAuth));
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Pace color={'#f4f6f8'} />
        {content}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
